exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-chat-support-js": () => import("./../../../src/pages/chat-support.js" /* webpackChunkName: "component---src-pages-chat-support-js" */),
  "component---src-pages-customer-support-js": () => import("./../../../src/pages/customer-support.js" /* webpackChunkName: "component---src-pages-customer-support-js" */),
  "component---src-pages-die-casting-js": () => import("./../../../src/pages/die-casting.js" /* webpackChunkName: "component---src-pages-die-casting-js" */),
  "component---src-pages-die-usage-list-js": () => import("./../../../src/pages/dieUsageList.js" /* webpackChunkName: "component---src-pages-die-usage-list-js" */),
  "component---src-pages-engineering-services-js": () => import("./../../../src/pages/engineering-services.js" /* webpackChunkName: "component---src-pages-engineering-services-js" */),
  "component---src-pages-fabrication-js": () => import("./../../../src/pages/fabrication.js" /* webpackChunkName: "component---src-pages-fabrication-js" */),
  "component---src-pages-forging-js": () => import("./../../../src/pages/forging.js" /* webpackChunkName: "component---src-pages-forging-js" */),
  "component---src-pages-forging-usage-list-js": () => import("./../../../src/pages/forgingUsageList.js" /* webpackChunkName: "component---src-pages-forging-usage-list-js" */),
  "component---src-pages-grey-iron-casting-js": () => import("./../../../src/pages/grey-iron-casting.js" /* webpackChunkName: "component---src-pages-grey-iron-casting-js" */),
  "component---src-pages-grey-iron-usage-list-js": () => import("./../../../src/pages/greyIronUsageList.js" /* webpackChunkName: "component---src-pages-grey-iron-usage-list-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investment-casting-js": () => import("./../../../src/pages/investment-casting.js" /* webpackChunkName: "component---src-pages-investment-casting-js" */),
  "component---src-pages-machining-js": () => import("./../../../src/pages/machining.js" /* webpackChunkName: "component---src-pages-machining-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-sand-casting-js": () => import("./../../../src/pages/sand-casting.js" /* webpackChunkName: "component---src-pages-sand-casting-js" */),
  "component---src-pages-sand-usage-list-js": () => import("./../../../src/pages/sandUsageList.js" /* webpackChunkName: "component---src-pages-sand-usage-list-js" */),
  "component---src-pages-seat-feature-js": () => import("./../../../src/pages/seat-feature.js" /* webpackChunkName: "component---src-pages-seat-feature-js" */),
  "component---src-pages-seat-finishing-js": () => import("./../../../src/pages/seat-finishing.js" /* webpackChunkName: "component---src-pages-seat-finishing-js" */),
  "component---src-pages-seat-installation-js": () => import("./../../../src/pages/seat-installation.js" /* webpackChunkName: "component---src-pages-seat-installation-js" */),
  "component---src-pages-seat-layout-js": () => import("./../../../src/pages/seat-layout.js" /* webpackChunkName: "component---src-pages-seat-layout-js" */),
  "component---src-pages-seatings-js": () => import("./../../../src/pages/seatings.js" /* webpackChunkName: "component---src-pages-seatings-js" */),
  "component---src-pages-three-dcanvas-js": () => import("./../../../src/pages/THREEDcanvas.js" /* webpackChunkName: "component---src-pages-three-dcanvas-js" */),
  "component---src-pages-use-script-js": () => import("./../../../src/pages/useScript.js" /* webpackChunkName: "component---src-pages-use-script-js" */)
}

